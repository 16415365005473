import axios from 'axios'

export const AgreementApi = {
    async Table(data){
        const response = await axios.post('/api/agreementoperations/table',data)
        return response
    },
    async Create(data){
        const response = await axios.post('/api/agreementoperations/create' , data)
        return response
    },
    async Detail(data){
        const response = await axios.get(`/api/agreementoperations/detail/${data}`)
        return response
    },
    async Delete(data){
        const response = await axios.post(`/api/agreementoperations/delete/${data}`)
        return response
    },
    async AddRoute(data){
        const response = await axios.post(`/api/agreementoperations/add/route`,data)
        return response
    },
    async Download(data){
        const response = await axios.post(`/api/agreementoperations/download/document/${data}`)
        return response
    },
    async UpdateInflationRate(data){
        const response = await axios.post('/api/agreementoperations/update/inflation',data)
        return response
    },
    async UpdateRoutePrice(data){
        const response = await axios.post('/api/agreementoperations/update/routeprice',data)
        return response
    },
    async UploadExcel(data){
        const response = await axios.post(`/api/agreementoperations/excelupload`,data)
        return response
    },
    async DownloadSampleFile(){
        const response = await axios.get(`/api/agreementoperations/downloadsamplefile`,{
            responseType: 'blob',
        })
        return response
    },
    async DownloadRoutes(id){
        const response = await axios.get(`/api/agreementoperations/download/agreementroutes/${id}`,{
            responseType: 'blob',
        })
        return response
    },
    async UploadAgreementDocument(data){
        const response = await axios.post('/api/agreementoperations/upload/agreementdocument',data)
        return response
    },
    async PriceChangeHistory(id){
        const response = await axios.get(`/api/agreementoperations/get/agreementroutepricechangehistory/${id}`)
        return response
    },
    Out:{
        async Create(data){
            const response = await axios.post('/api/agreementoperations/create/out' , data)
            return response
        },
        async Table(data){
            const response = await axios.post('/api/agreementoperations/table/out' , data)
            return response
        },
    }
}